import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { AuthenticationProvider, useAuthentication } from "@divide/ois-react-components";
import authenticationOptionsMap from "constants/authenticationOptionsMap";
import { RecoilRoot } from "recoil";
import { StartUpProvider } from "@divide/retailsuite-react-components";
import appConfig from "constants/appConfig";
import storageKeys from "constants/storageKeys";
import App from "parts/App";
import "react-toastify/dist/ReactToastify.css";

const container = document.querySelector("#root");
const root = createRoot(container);

root.render(
    <RecoilRoot>
        <AuthenticationProvider optionsMap={authenticationOptionsMap} appGroup="rs">
            <StartUpProvider {...{ useAuthentication, appConfig, storageKeys, toast }}>
                <BrowserRouter>
                    <ToastContainer limit={5} />
                    <App />
                </BrowserRouter>
            </StartUpProvider>
        </AuthenticationProvider>
    </RecoilRoot>
);