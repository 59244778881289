const env = process.env;
const urlParams = new URLSearchParams(window.location.search);

const authenticationOptionsMap = new Map([
    ["auth0", {
        domain: "divide.eu.auth0.com",
        scope: "profile openid email chooseBranch admin get:configuration get:endpoints get:settings get:tritonxtoken",
        client_id: env.REACT_APP_AUTH0_CLIENT_ID,
        audience: env.REACT_APP_AUTH0_AUDIENCE,
        redirect_uri: window.location.origin,
        organization: undefined,
        invitation: urlParams.get("invitation") || ""
    }],
    ["entra", {
        auth: {
            clientId: env.REACT_APP_MSENTRA_CLIENT_ID,
            authority: env.REACT_APP_MSENTRA_AUTHORITY,
            scopes: [],
            redirectUri: window.location.origin
        }
    }]
]);

export default authenticationOptionsMap;