import { createContext } from "react";
import { useRoutes } from "react-router-dom";
import { useTranslations, useResolveRoutesByAuthentication } from "@divide/ois-react-hooks";
import { useAuthentication } from "@divide/ois-react-components";
import { useCultureCode, useGetSettingsGroup, useGetConfigurationGroup, useGetUserHasAccess } from "@divide/retailsuite-react-hooks";

import { Loader } from "@divide/retailsuite-react-components";
import useSetCdnDomainToStorage from "hooks/general/useSetCdnDomainToStorage";
import { privateRoutes, publicRoutes } from "constants/routes"
import appConfig from "constants/appConfig";
import storageKeys from "constants/storageKeys";
import translations from "constants/translations";
import SecurityCodeDialog from "parts/SecurityCodeDialog";
import "./App.scss";

export const AppContext = createContext();

export function App() {
    const { routes, element } = useResolveRoutesByAuthentication(useAuthentication, useRoutes, privateRoutes, publicRoutes);
    const webshopSettings = useGetSettingsGroup(useAuthentication, appConfig, "Webshop");
    const generalConfiguration = useGetConfigurationGroup(useAuthentication, appConfig, "General");
    const siConfiguration = useGetConfigurationGroup(useAuthentication, appConfig, "SL");

    useSetCdnDomainToStorage(webshopSettings.CdnDomain);

    const { loading } = useAuthentication();
    const { cultureCode, setCultureCode } = useCultureCode(storageKeys.cultureCode);
    const { translation } = useTranslations(cultureCode, translations);
    const userAccess = useGetUserHasAccess(useAuthentication, 9);

    return loading ? (
        <Loader fullscreen={true} text={translation("messages.loading.default")} />
    ) : (
        <AppContext.Provider value={{ 
            cultureCode, 
            setCultureCode, 
            translation, 
            userAccess, 
            webshopSettings,
            generalConfiguration,
            siConfiguration,
            routes
        }}>
            <SecurityCodeDialog />
            {element}
        </AppContext.Provider>
    );
}
